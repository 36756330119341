//Profile Component
import React from 'react';

import "../../styles/section-element.css";


function profile(props) {
    const image = require('../../assets/' + props.details.image)
    console.log(props)
    return(
        <div className= "element-container">
            <a href={props.details.linkedin}><img className="img" src={image} alt="logo"/></a>
            <div className="element-h1">{props.details.name}</div>
            <div className="profile-title">{props.details.title}</div>
            <div className="description">{props.details.description}</div>
        </div>
    )
}
export default profile;