//Solution Component
import React, {useState} from 'react';
import Carousel from "react-multi-carousel";

// Import Components
import SolutionStepsDesktop from "../solution-steps/index-desktop";
import SolutionSteps from "../solution-steps/index-mobile";

// Import styles
import "../../styles/text.css";
import "../../styles/mission.css";
import "../../styles/solutionStepsMobile.css";
import "../../styles/home.css";
import "../../styles/solution.css";

// Import Images
import Solution from "../../assets/EL_Master Image 003.png";
import SolutionDesktop from "../../assets/EL_Solution.png";

// Import json
import solutionData from "../home-content/solution-carousel.json";



function solution(props) {

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 2, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    const solutionCarouselInput = solutionData.map((item, index) =>{
        return(
            <div className="carousel-image-container carousel-image-div">
                <SolutionSteps key={index} details={item}/>
            </div>
        );
    });

    const solutionStepsDesktop = solutionData.map((item, index) =>{
        return(
            <div>
                <SolutionSteps key={index} details={item}/>
            </div>
        );
    });

    return(
        <div className="section">
            <div className="section-subtitle">The Solution</div>
            <div className="section-title">An AI Co-Pilot for Eco-Design</div> 
            <div className="section-descr">We believe it is time that the same incredible software tools that support scientists in identifying drug targets with a higher success rate are also built to accelerate sustainable manufacturing. To do that, we are building an AI-powered software tool that acts as a partner for the scientist, engineer or product designer tasked with designing or optimising a manufacturing process to improve its sustainability impact (from emissions to biodiversity loss). </div>
            <img className="solution solution-img-container" src={Solution} alt="solution"/> 
            <div className = "solution-steps-container">
                {solutionStepsDesktop}
            </div>
        </div>
    )
}
export default solution;