import React from 'react';

// Import components
import Section from "../modules/section";

// Import styles
import "../styles/text.css";
import "../styles/page.css";
import "../styles/mission.css";

// Import content
import missionData from "../modules/element/mission.json";

//Import images
import Earthshot from "../assets/mission/Earthshot-Final.png";

function mission(){

    const sections = missionData.map((item, index) =>{
        return(
            <Section key={index} details={item}/>
        );
    });

    return(
        <div className = "mission-container">
            <div className='mission-top-container'>
                <div className="title-container">
                    <div className= "page-title">Mission Control for Climate Action</div>
                </div>
                <img id="mission-banner" src={Earthshot} alt="mission-banner" />
            </div>
            <div className="inner-page-container">
                {sections}
            </div>
        </div>
    )
}
export default mission;