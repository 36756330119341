import React, {useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';

// Import components
import Menu from "../components/menu";
import HeaderMobile from "./header-mobile";

// Import images
import logoLight from '../assets/EL_Logo/EL_Logo_White@3x.png';
import logoDark from '../assets/EL_Logo/EL_Logo-Charcoal@3x.png';

// Import styles
import "../styles/header.css";
import "../styles/page.css";

function header(){
    const [open, setOpen] = useState(false);
    const { pathname } = useLocation();

    return(
        <div className={`navbar ${pathname === '/mission' ? 'navbar-light' : pathname === '/team' ? 'navbar-gradient' : 'navbar-dark'}`}>
            <div className="inner-navbar">
                <div className="header-mobile-hide">
                    <ul>
                        <li><NavLink className={`nav-text ${pathname === '/mission' ? 'nav-text-dark' : pathname === '/team' ? 'nav-text-transl' : 'nav-text-light'}`} exact activeClassName="active" to="/">Home</NavLink></li>
                        <li><NavLink className={`nav-text ${pathname === '/mission' ? 'nav-text-dark' : pathname === '/team' ? 'nav-text-transl' : 'nav-text-light'}`} activeClassName = "active" to="/mission">Our Earthshot</NavLink></li>
                        <li><NavLink to="/"><img id="logo" src={pathname === '/mission' ? logoDark : pathname === '/team' ? logoDark : logoLight} alt="logo" activeClassName = "deactive"/></NavLink></li>
                        <li><NavLink className={`nav-text ${pathname === '/mission' ? 'nav-text-dark' : pathname === '/team' ? 'nav-text-transl' : 'nav-text-light'}`} activeClassName = "active" to="/team">Who We Are</NavLink></li>
                        <li><a className={`nav-text ${pathname === '/mission' ? 'nav-text-dark' : pathname === '/team' ? 'nav-text-transl' : 'nav-text-light'}`} activeClassName = "active" href="https://elioearth.substack.com">Updates</a></li>
                    </ul>
                </div>
                <div className="header-desktop-hide">
                    <HeaderMobile open={open} setOpen={setOpen}/>
                </div>
            </div>
            <Menu open={open} setOpen={setOpen}/>
        </div>
    )

}

export default header;