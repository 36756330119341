//Footer Component
import React, {useState} from 'react';
import {NavLink} from 'react-router-dom'

import "../styles/footer.css";
import logo from "../assets/EL_Logo/EL_Logo-Text_White.png";
import linkedin from "../assets/logos/LinkedIn.png";
import twitter from "../assets/logos/Twitter.png";
import instagram from "../assets/logos/white/IG@2x.png";

function footer(){
    /*
    function resize(frame){
        console.log(window.innerWidth);
        this.setState({width: window.innerWidth*0.4})
    };
    */

    return(
        <div className="footer-grid">
            <iframe id="footer-substack" title="Substack Signup" src="https://elioearth.substack.com/embed" className="footer-substack" frameborder="0" scrolling="no"></iframe>
            <img className="footer-logo mobile-hide" src={logo} alt="logo"/>
            <div id="footer-nav" className="footer-clmn1-grid">
                <div>
                    <NavLink className="footer-nav-text" to="/">Home</NavLink>
                    <NavLink className="footer-nav-text" to="/mission" >Our Earthshot</NavLink>
                    <NavLink className="footer-nav-text" to="/mission" >Who we are</NavLink>
                    <a className="footer-nav-text" href="https://elioearth.substack.com" >Updates</a>
                </div>
                <div>
                    <a className="footer-contact-text" href="mailto:founders@elio.earth">hello@elio.earth</a>
                    <div className="socials-grid">
                        <a href="https://www.linkedin.com/company/elio-earth"><img id="socials" src={linkedin} alt="linkedin"/></a>
                        <a href="https://twitter.com/ElioEarth"><img id="socials" src={twitter} alt="twitter"/></a>
                        <a href="https://www.instagram.com/elio.earth"><img id="socials" src={instagram} alt="instagram"/></a>
                    </div>
                </div>                   
            </div>
        </div>
    )
}
export default footer;
