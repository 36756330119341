//Page Section Component
import React from 'react';

import "../../styles/text.css";
import "../../styles/mission.css";


function sectionNoGrid(props) {

    return(
        <div className="section">
            <div className="section-subtitle">{props.details.section}</div>
            <div className="section-title">{props.details.title}</div>
            <div className="section-descr">{props.details.content}</div>
        </div>
    )
}
export default sectionNoGrid;