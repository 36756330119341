//Solution Component
import React, {useState} from 'react';

//Import styles
import "../../styles/text.css";
import "../../styles/mission.css";
import "../../styles/home.css";

//Import images
import SolutionCircle from "../../assets/EL_Solution-Circle.png";
import SolutionLine from "../../assets/EL_Solution-Line.png";


function solutionSteps(props) {
    return(
        <div className = "steps-grid">
            <img id="solution-circle" src={SolutionCircle} alt="solution-circle"/>
            <div className="elio-product">{props.details.heading}</div>
            <img id="solution-line" src={SolutionLine} alt="solution-line"/>
            <div>
                <div className="step-descr">{props.details.content}</div>
            </div>
        </div>
    )
}
export default solutionSteps;
