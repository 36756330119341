//Problem Component
import React from 'react';

import "../../styles/section-element.css";


function element(props) {
    const image = require('../../assets/' + props.details.image)
    return(
        <div className= "element-container">
            <img className="img" src={image} alt="logo"/>
            <div className="element-h1">{props.details.title}</div>
            <div className="description">{props.details.content}</div>
        </div>
    )
}
export default element;