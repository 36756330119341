import React from "react";
import {Helmet} from "react-helmet";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

//css
import './App.css';

//Import Containers
import Home from './pages/home';
import Mission from './pages/mission';
import Team from './pages/team';
import Heading from './components/header';
import Footer from "./components/footer";
import Banner from "./components/banner";

function App() {
  return (
    <div>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/ueb5zfy.css"></link>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>
      <Router>
        <Banner/>
        <Heading/>
        <Switch>
          <Route exact path= "/" component={Home}/>
          <Route path= "/mission" component={Mission}/>
          <Route path= "/team" component={Team}/>
        </Switch>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
