import React from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import { bool } from 'prop-types';

import "../styles/header.css";

const Menu = ({open, setOpen}) => {
    const { pathname } = useLocation();
    return(
        <div className={!open ? 'menu-container' : pathname === '/mission' ? 'menu-container-light active' : pathname === '/team' ? 'menu-container-gradient active' : 'menu-container-dark active'}>
            <div className="menu-grid">
                <NavLink className={`nav-text ${pathname === '/mission' ? 'nav-text-dark' : pathname === '/team' ? 'nav-text-transl' : 'nav-text-light'}`} exact activeClassName="active" to="/" onClick={() => setOpen(!open)}>Home</NavLink>
                <NavLink className={`nav-text ${pathname === '/mission' ? 'nav-text-dark' : pathname === '/team' ? 'nav-text-transl' : 'nav-text-light'}`} activeClassName="active" to="/mission" onClick={() => setOpen(!open)}>Our Earthshot</NavLink>
                <NavLink className={`nav-text ${pathname === '/mission' ? 'nav-text-dark' : pathname === '/team' ? 'nav-text-transl' : 'nav-text-light'}`} activeClassName="active" to="/team" onClick={() => setOpen(!open)}>Who We Are</NavLink>
                <a className={`nav-text ${pathname === '/mission' ? 'nav-text-dark' : pathname === '/team' ? 'nav-text-transl' : 'nav-text-light'}`} activeClassName="active" href="https://elioearth.substack.com" onClick={() => setOpen(!open)}>Updates</a>
            </div>
        </div>
    )
}
Menu.propTypes = {
    open: bool.isRequired,
}
export default Menu;