//Page Section Component
import React from 'react';

import "../../styles/text.css";
import "../../styles/mission.css";
import "../../styles/section-element.css";

// Import components
import Element from "../../modules/element";

import imgTrial from "../../assets/EL_Problem-Charity.png";


function section(props) {
    var count = 0;
    var gridExist = false;
    var elements = <></>;
    var image = '';

    if (props.details.elements != null){
        gridExist = true;
        elements =  props.details.elements.map((item, index) => {
            count = count + 1;
            return(
                <Element key={index} details={item}/>
            );
        });
    }

    if (props.details.img != null){
        image = require('../../assets/' + props.details.img);
    };
   
    return(
        <div className="section">
            <div className="section-subtitle">{props.details.section}</div>
            <div className="section-title">{props.details.title}</div>
            <div className="section-descr">{props.details.content}</div>
            <img className={`${image != '' ? 'section-img' : ''}`} src={image}/>
            <div className={`${gridExist === true ? 'grid-container' : ''}`} style={{gridTemplateColumns : `repeat(${count}, 300px)`}}>
                {elements}
            </div> 
        </div>
    )
}
export default section;