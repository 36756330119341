import React from 'react';
import { bool, func } from 'prop-types';
import {NavLink, useLocation} from 'react-router-dom';

//Import Images
import hamburgerLight from "../assets/header/Hamburger-White.svg";
import hamburgerDark from "../assets/header/Hamburger-Black.svg";
import closeDark from "../assets/header/EL_Close_Black.svg";
import closeLight from "../assets/header/EL_Close_White.svg";
import logoLight from '../assets/EL_Logo/EL_Logo_White@3x.png';
import logoDark from '../assets/EL_Logo/EL_Logo-Charcoal@3x.png';

//Import styles
import "../styles/text.css";
import "../styles/page.css";
import "../styles/header.css";

function headerMobile({open, setOpen}){
    const { pathname } = useLocation();
    return(
        <>
            <div className="header-grid">
                <NavLink to="/" className="col-2"><img id="logo-mobile" src={pathname === '/mission' ? logoDark : pathname === '/team' ? logoDark : logoLight} alt="logo" activeClassName = "deactive"/></NavLink>
                <img id="hamburger" src={pathname === '/mission' ? hamburgerDark : pathname === '/team' ? hamburgerDark : hamburgerLight} alt="hamburger" open={open} onClick={() => setOpen(!open)} className={open ? 'burger-open' : 'burger'}/>
                <img id="close-icon" src={pathname === '/mission' ? closeDark : pathname === '/team' ? closeDark : closeLight} alt="close" open={open} onClick={() => setOpen(!open)} className={open ? 'close-open' : 'close'}/>
            </div>
        </>
    )
}
headerMobile.protoTypes = {
    open: bool.isRequired,
    setOpen: func.isRequired,
};
export default headerMobile;
