import React from 'react';
import Carousel from "react-multi-carousel";

//Import Components
import Solution from "../modules/solution";
import SectionNoGrid from "../modules/section-no-grid";

//Import Images
import home_img from '../assets/home/Elio_Earth_Uncover-3.png';
import "react-multi-carousel/lib/styles.css";

// Import json
import introData from "../modules/home-content/elio-intro.json";

//Import styles
import "../styles/home.css";
import "../styles/text.css";
import "../styles/page.css";

function home() {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 2, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 720 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 720, min: 0 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    const sectionsNoGrid = introData.map((item, index) =>{
        return(
            <SectionNoGrid key={index} details={item}/>
        );
    });

    return(
        <div className = "home-container">
            <div className='home-top-container'>
                <div className = "text-container mobile-hide">
                    <div className = "home-title">Your Co-Pilot for Sustainable Drug Manufacturing</div>
                    <button className = "button"><a className="button-text" href="https://docs.google.com/forms/d/e/1FAIpQLScr_6_qOLbSxuyAWIAd8NQAHRjRPlN305BOf0zrpQialtDcCg/viewform?usp=sf_link">Join waitlist</a></button>
                    <div className='seen-in-container'>
                        <div className='seen-in-title'>PRESENTED IN, AT OR BY</div>
                        <div className='seen-in-logos-container'>
                            <a href="https://www.youtube.com/watch?v=-gr1QvHmmtw&themeRefresh=1"><img className="seen-in-logo" src={require('../assets/logos/mlightblue/mlightblue-CNBC@2x.png')} alt="logo" /></a>
                            <a href="https://www.youtube.com/watch?v=5TbzGglSIsk"><img className="seen-in-logo" src={require('../assets/logos/mlightblue/mlightblue-WEF@2x.png')} alt="logo" /></a>
                            <a href="https://www.alpbach.org/"><img className="seen-in-logo" src={require('../assets/logos/mlightblue/mlightblue-EFA@2x.png')} alt="logo" /></a>
                            <img className="seen-in-logo" src={require('../assets/logos/mlightblue/mlightblue-UNDP@2x.png')} alt="logo" />
                        </div>
                    </div>
                </div>
                <div className="text-container desktop-hide">
                    <div className = "home-title">Your Co-Pilot for Sustainable Drug Manufacturing</div>
                </div>
                <img id="home_img" src={home_img} alt="logo" />
                <button className = "button desktop-hide"><a className="button-text" href="https://docs.google.com/forms/d/e/1FAIpQLScr_6_qOLbSxuyAWIAd8NQAHRjRPlN305BOf0zrpQialtDcCg/viewform?usp=sf_link">Join waitlist</a></button>
                <div className='seen-in-container desktop-hide'>
                        <div className='seen-in-title'>PRESENTED IN, AT OR BY</div>
                        <div className='seen-in-logos-container'>
                            <a href="https://www.youtube.com/watch?v=-gr1QvHmmtw&themeRefresh=1"><img className="seen-in-logo" src={require('../assets/logos/mlightblue/mlightblue-CNBC@2x.png')} alt="logo" /></a>
                            <a href="https://www.youtube.com/watch?v=5TbzGglSIsk"><img className="seen-in-logo" src={require('../assets/logos/mlightblue/mlightblue-WEF@2x.png')} alt="logo" /></a>
                            <a href="https://www.alpbach.org/"><img className="seen-in-logo" src={require('../assets/logos/mlightblue/mlightblue-EFA@2x.png')} alt="logo" /></a>
                            <img className="seen-in-logo" src={require('../assets/logos/mlightblue/mlightblue-UNDP@2x.png')} alt="logo" />
                        </div>
                    </div>
            </div>
            <div className='support-background'>
                <div className="carousel-grid-container">
                    <div className="support-title">WITH SUPPORT FROM</div>
                    <Carousel
                        swipeable
                        draggable
                        responsive={responsive}
                        infinite={true}
                        autoPlay
                        autoPlaySpeed={5000}
                        keyBoardControl={true}
                        customTransition="all 1s"
                        transitionDuration={2000}
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        containerClass="carousel-container"
                        // itemClass="carousel-item-padding-0-px"
                    >
                        <a href="https://www.techstars.com/newsroom/meet-the-companies-in-the-2021-techstars-sustainability-accelerator"><div className="carousel-image-container carousel-image-div"> <img className="carousel-image" src={require('../assets/logos/blue/blue-techstars@2x.png')} alt="logo" /> </div></a>
                        <a href="https://twitter.com/776foundation/status/1556639950371782656?s=20&t=QeMKlFWy9AsSdA3ZFJKJbQ"><div className="carousel-image-container carousel-image-div"> <img className="carousel-image" src={require('../assets/logos/blue/blue-776@2x.png')} alt="logo" /> </div></a>
                        <a href="https://www.nature.org/en-us/about-us/who-we-are/how-we-work/technology-and-innovation/techstars-sustainability-accelerator/2021-techstars-startups/"><div className="carousel-image-container carousel-image-div"> <img className="carousel-image" src={require('../assets/logos/blue/blue-tnc@2x.png')} alt="logo" /> </div></a>
                        <a href="https://innovationlabs.harvard.edu/about/news/harvard-climate-entrepreneurs-circle-and-climate-rising-podcast/"><div className="carousel-image-container carousel-image-div"> <img className="carousel-image" src={require('../assets/logos/blue/blue-hlabs@2x.png')} alt="logo" /> </div></a>
                        <a href="https://www.climatecapital.co"><div className="carousel-image-container carousel-image-div"> <img className="carousel-image" src={require('../assets/logos/blue/blue-climatecap@2x.png')} alt="logo" /> </div></a>
                        <a href="http://granpool.com/?pg=current-portfolio"><div className="carousel-image-container carousel-image-div"> <img className="carousel-image" src={require('../assets/logos/blue/blue-granpool@2x.png')} alt="logo" /> </div></a>
                        <a href="https://www.harvardclimate.com/news/announcing-the-harvard-climate-entrepreneurs-circle"><div className="carousel-image-container carousel-image-div"> <img className="carousel-image" src={require('../assets/logos/blue/blue-hace@2x.png')} alt="logo" /> </div></a>
                        <div className="carousel-image-container carousel-image-div"> <img className="carousel-image" src={require('../assets/logos/blue/blue-htechfellow@2x.png')} alt="logo" /> </div>
                    </Carousel>
                </div>
            </div>
            <Solution/>
        </div>
    )
}
export default home;
