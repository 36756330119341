import React from 'react';
import "../styles/banner.css";

const Banner = () => {
    return(
        <div className='banner'>
            <div className='banner-text-container'><a className='banner-text' href="https://www.ten23.health/fileadmin/files/downloads/press_releases/230703_ten23_press_release_elio_engl.pdf">NEWS: Elio partners with Swiss pharma CDMO ten23 health</a></div>
        </div>
    )
}
export default Banner;