import React from 'react';

// Import components
import Profile from "../modules/team";
import Section from "../modules/section";
import Solution from "../modules/solution";

// Import styles
import "../styles/text.css";
import "../styles/page.css";
import "../styles/team.css";

// Import content
import advisorsData from "../modules/team/profile-advisors.json";

//Import images
import Problem from "../assets/mission/EL_Bird-Over-Chasm.png";
import TeamBanner from "../assets/team/team-banner.jpg";
import Kami from "../assets/team/Elio-leadership_Kami-Full.png";
import Kamil from "../assets/team/Elio-leadership_Kamil-Full.png";
import harvard from "../assets/logos/harvard.png";
import mit from "../assets/logos/MIT.png";
import undp from "../assets/logos/undp.png";
import mite from "../assets/logos/mite.jpg";
import sense360 from "../assets/logos/sense360.png";
import thinknear from "../assets/logos/thinknear.png";
import uwaterloo from "../assets/logos/uwaterloo.png";

function team(){
    const profileAdvisors =  advisorsData.map((item, index) => {
        //const img = path.join(dirname, item.image)
        return(
            <Profile key={index} details={item}/>
        );
    });

    return(
        <div className = "team-container">
            <div className='crop-img'><img id="team-banner" src={TeamBanner} alt="team-banner" /></div>
            <div className="inner-page-container">
                <div className='section team-summary-cont'>
                    <div className='section-descr'>At Elio, we are driven by the belief that climate action is a team sport and needs a common communication and coordination platform to accelerate climate action and build the future we need together. For us, the way we run the company internally has to reflect the change we want to effect in the outside world, which is why we live by the following values:</div>
                    <div className='team-values-cont'>
                        <div className='team-value'>Compassion</div>
                        <div className='section-descr'>to receive feedback humbly in service of the just future we’re building.</div>
                        <div className='team-value'>Fortitude</div>
                        <div className='section-descr'>in face of the immense climate crisis & a bias to “fail” while daring greatly.</div>
                        <div className='team-value'>Gestalt</div>
                        <div className='section-descr'>so our team operates in supernatural synergy & we enable the world to do so too for climate action.</div>
                    </div>
                </div>
                <div className='team-mobile-hide team-tablet-hide'>
                    <div className='section team-leader'>
                        <a href='https://www.linkedin.com/in/kamikrista/'><img id="kami" src={Kami} alt="kami"/></a>
                        <div className='team-leader-sum'>
                            <div className='team-leader-tags'>
                                <div className='team-leader-tag'>Scientist</div>
                                <div className='tag-ellipse'/>
                                <div className='team-leader-tag'>Designer</div>
                                <div className='tag-ellipse'/>
                                <div className='team-leader-tag'>Engineer</div>
                                <div className='tag-ellipse'/>
                                <div className='team-leader-tag'>Activist</div>
                                <div className='tag-ellipse'/>
                                <div className='team-leader-tag'>Tree-hugger</div>
                            </div>
                            <div className='team-leader-descr'>
                                <div className='team-leader-header'>
                                    <div className='team-leader-title'>Kami Krista</div>
                                    <div className='team-leader-subtitle'>Co-founder & CEO</div>
                                </div>
                                <div className='section-descr'>Kami is a member of the UN Youth4Climate Advisory Committee and was a youth delegate for Austria at the UN PreCOP in Milan and at COP26 in Glasgow. He also built the 200-person volunteer translation team for the leading climate education NGO ClimateScience, and in his short time on this planet has researched a novel idea for HIV therapies at MIT, Harvard, the Broad Institute and MedUni Vienna. From a young age, Kami has sought to accelerate the transition to a sustainable age. At age eight, Kami wrote to the Brazilian president and at age eleven he gave a TEDx talk on sustainable transport. Kami studied bioengineering at Harvard and is a fellow with Sigma Squared and Alexis Ohanian's 776 Foundation.</div>
                            </div>
                            <div className='team-leader-logos'>
                                <img id="harvard" className='team-leader-logo' src={harvard} alt="harvard"/>
                                <img id="mit" src={mit} alt="mit"/>
                                <img id="undp" src={undp} alt="undp"/>
                                <img id="mite" src={mite} alt="mite"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='section team-desktop-hide team-mobile-hide'>
                    <div className='team-leader-tablet'>
                        <div className='team-leader-sum-1'>
                            <a href='https://www.linkedin.com/in/kamikrista/'><img id="kami" src={Kami} alt="kami"/></a>
                            <div className='team-leader-sum'>
                                <div className='team-leader-tags'>
                                    <div className='team-leader-tag'>Scientist</div>
                                    <div className='tag-ellipse'/>
                                    <div className='team-leader-tag'>Designer</div>
                                    <div className='tag-ellipse'/>
                                    <div className='team-leader-tag'>Engineer</div>
                                    <div className='tag-ellipse'/>
                                    <div className='team-leader-tag'>Activist</div>
                                    <div className='tag-ellipse'/>
                                    <div className='team-leader-tag'>Tree-hugger</div>
                                </div>
                                <div className='team-leader-descr'>
                                    <div className='team-leader-header'>
                                        <div className='team-leader-title'>Kami Krista</div>
                                        <div className='team-leader-subtitle'>Co-founder & CEO</div>
                                    </div>
                                </div>
                                <div className='team-leader-logos'>
                                    <img id="harvard" className='team-leader-logo' src={harvard} alt="harvard"/>
                                    <img id="mit" src={mit} alt="mit"/>
                                    <img id="undp" src={undp} alt="undp"/>
                                    <img id="mite" src={mite} alt="mite"/>
                                </div>
                            </div>
                        </div>
                        <div className='section-descr team-leader-detail'>Kami is a member of the UN Youth4Climate Advisory Committee and was a youth delegate for Austria at the UN PreCOP in Milan and at COP26 in Glasgow. He also built the 200-person volunteer translation team for the leading climate education NGO ClimateScience, and in his short time on this planet has researched a novel idea for HIV therapies at MIT, Harvard, the Broad Institute and MedUni Vienna. From a young age, Kami has sought to accelerate the transition to a sustainable age. At age eight, Kami wrote to the Brazilian president and at age eleven he gave a TEDx talk on sustainable transport. Kami studied bioengineering at Harvard and is a fellow with Sigma Squared and Alexis Ohanian's 776 Foundation.</div>
                    </div>
                </div>
                <div className='section team-desktop-hide team-tablet-hide'>
                    <div className='team-leader-mobile'>
                        <a href='https://www.linkedin.com/in/kamikrista/'><img id="kami" src={Kami} alt="kami"/></a>
                        <div className='team-leader-sum'>
                            <div className='team-leader-tags'>
                                <div className='team-leader-tag'>Scientist</div>
                                <div className='tag-ellipse'/>
                                <div className='team-leader-tag'>Designer</div>
                                <div className='tag-ellipse'/>
                                <div className='team-leader-tag'>Engineer</div>
                                <div className='tag-ellipse'/>
                                <div className='team-leader-tag'>Activist</div>
                                <div className='tag-ellipse'/>
                                <div className='team-leader-tag'>Tree-hugger</div>
                            </div>
                            <div className='team-leader-descr'>
                                <div className='team-leader-header'>
                                    <div className='team-leader-title'>Kami Krista</div>
                                    <div className='team-leader-subtitle'>Co-founder & CEO</div>
                                </div>
                                <div className='section-descr'>Kami is a member of the UN Youth4Climate Advisory Committee and was a youth delegate for Austria at the UN PreCOP in Milan and at COP26 in Glasgow. He also built the 200-person volunteer translation team for the leading climate education NGO ClimateScience, and in his short time on this planet has researched a novel idea for HIV therapies at MIT, Harvard, the Broad Institute and MedUni Vienna. From a young age, Kami has sought to accelerate the transition to a sustainable age. At age eight, Kami wrote to the Brazilian president and at age eleven he gave a TEDx talk on sustainable transport. Kami studied bioengineering at Harvard and is a fellow with Sigma Squared and Alexis Ohanian's 776 Foundation.</div>
                            </div>
                            <div className='team-leader-logos'>
                                <img id="harvard" className='team-leader-logo' src={harvard} alt="harvard"/>
                                <img id="mit" src={mit} alt="mit"/>
                                <img id="undp" src={undp} alt="undp"/>
                                <img id="mite" src={mite} alt="mite"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='team-mobile-hide team-tablet-hide'>
                    <div className='section team-leader'>
                        <div className='team-leader-sum'>
                            <div className='team-leader-tags'>
                                 <div className='team-leader-tag'>Software Engineer</div>
                                <div className='tag-ellipse'/>
                                <div className='team-leader-tag'>Startup Junkie</div>
                                <div className='tag-ellipse'/>
                                <div className='team-leader-tag'>Nerd-Athlete</div>
                            </div>
                            <div className='team-leader-descr'>
                                <div className='team-leader-header'>
                                    <div className='team-leader-title'>Kamil Mroczek</div>
                                    <div className='team-leader-subtitle'>Co-founder & CTO</div>
                                </div>
                                <div className='section-descr'>Kamil has two successful exits to public companies under his belt, one as cofounder of Sense360 (acq. Medallia), a B2B big data & ML data platform and one as the 3rd employee of Thinknear (acq. Telenav). He started his software engineering career at Vistaprint and studied Computer Science at the University of Waterloo. During the pandemic, he started a quest to perfect the whole wheat pizza. Outside of Elio, he is usually found on the soccer field, proving he is still a match to up and coming youngsters.</div>
                            </div>
                            <div className='team-leader-logos'>
                                <img id="sense360" src={sense360} alt="sense360"/>
                                <img id="thinknear" src={thinknear} alt="thinknear"/>
                                <img id="uwaterloo" src={uwaterloo} alt="uwaterloo"/>
                            </div>
                        </div>
                        <a href="https://www.linkedin.com/in/kamilmroczek/"><img id="kamil" src={Kamil} alt="kamil"/></a>
                    </div>
                </div>
                <div className='section team-desktop-hide team-mobile-hide'>
                    <div className='team-leader-tablet'>
                        <div className='team-leader-sum-1'>
                            <div className='team-leader-sum'>
                                <div className='team-leader-tags'>
                                    <div className='team-leader-tag'>Software Engineer</div>
                                    <div className='tag-ellipse'/>
                                    <div className='team-leader-tag'>Startup Junkie</div>
                                    <div className='tag-ellipse'/>
                                    <div className='team-leader-tag'>Nerd-Athlete</div>
                                </div>
                                <div className='team-leader-descr'>
                                    <div className='team-leader-header'>
                                        <div className='team-leader-title'>Kamil Mroczek</div>
                                        <div className='team-leader-subtitle'>Co-founder & CTO</div>
                                    </div>
                                </div>
                                <div className='team-leader-logos'>
                                    <img id="sense360" src={sense360} alt="sense360"/>
                                    <img id="thinknear" src={thinknear} alt="thinknear"/>
                                    <img id="uwaterloo" src={uwaterloo} alt="uwaterloo"/>
                                </div>
                            </div>
                            <a href="https://www.linkedin.com/in/kamilmroczek/"><img id="kamil" src={Kamil} alt="kamil"/></a>
                        </div>
                        <div className='section-descr team-leader-detail'>Kamil has two successful exits to public companies under his belt, one as cofounder of Sense360 (acq. Medallia), a B2B big data & ML data platform and one as the 3rd employee of Thinknear (acq. Telenav). He started his software engineering career at Vistaprint and studied Computer Science at the University of Waterloo. During the pandemic, he started a quest to perfect the whole wheat pizza. Outside of Elio, he is usually found on the soccer field, proving he is still a match to up and coming youngsters.</div>
                    </div>
                </div>
                <div className='section team-desktop-hide team-tablet-hide'>
                    <div className='team-leader-mobile'>
                        <a href="https://www.linkedin.com/in/kamilmroczek/"><img id="kamil" src={Kamil} alt="kamil"/></a>
                        <div className='team-leader-sum'>
                            <div className='team-leader-tags'>
                                <div className='team-leader-tag'>Software Engineer</div>
                                <div className='tag-ellipse'/>
                                <div className='team-leader-tag'>Startup Junkie</div>
                                <div className='tag-ellipse'/>
                                <div className='team-leader-tag'>Nerd-Athlete</div>
                            </div>
                            <div className='team-leader-descr'>
                                <div className='team-leader-header'>
                                    <div className='team-leader-title'>Kamil Mroczek</div>
                                    <div className='team-leader-subtitle'>Co-founder & CTO</div>
                                </div>
                                <div className='section-descr'>Kamil has two successful exits to public companies under his belt, one as cofounder of Sense360 (acq. Medallia), a B2B big data & ML data platform and one as the 3rd employee of Thinknear (acq. Telenav). He started his software engineering career at Vistaprint and studied Computer Science at the University of Waterloo. During the pandemic, he started a quest to perfect the whole wheat pizza. Outside of Elio, he is usually found on the soccer field, proving he is still a match to up and coming youngsters.</div>
                            </div>
                            <div className='team-leader-logos'>
                                <img className="team-logo" id="sense360" src={sense360} alt="sense360"/>
                                <img className="team-logo" id="thinknear" src={thinknear} alt="thinknear"/>
                                <img className="team-logo" id="uwaterloo" src={uwaterloo} alt="uwaterloo"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className= "section-subtitle">Our Advisors</div>
                    <div className= "section-title">Leaders in Pharma, Tech for Pharma & AI for Scientific Comms</div>
                    <div className= "profile-grid-container">
                        {profileAdvisors}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default team;